@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label>label {
    color: $textColor;
  }

  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }

  &-item {
    margin-bottom: 10px;
  }
}

.invoice-form {
  .#{$prefix}-item-control {
    max-width: unset;
  }
}

.company-account-form {
  min-width: 100%;
  max-width: 100%;
  border: 1px solid $borderColor;
  padding: 8px 24px 0 8px;
  background: rgba(255, 255, 255, 0.5);
  margin-bottom: 16px;
  border-radius: 4px;
  position: relative;

  >.#{$prefix}-item {
    min-width: 100%;
    max-width: 100%;

    .#{$prefix}-item-label,
    .#{$prefix}-item-control {
      min-width: 50%;
      max-width: 50%;

      label {
        justify-content: flex-end;
        min-width: 100%;
        display: flex;
      }
    }
  }

  .anticon-close {
    position: absolute;
    top: 2px;
    right: 2px;
    color: $disabledColor;

    &:hover {
      color: $errorColor;
    }
  }
}

.invoice-articles-form {
  min-width: 100%;
  max-width: 100%;

  >.#{$antPrefix}-row {
    min-width: 100%;
    max-width: 100%;

    .#{$prefix}-item-label,
    .#{$prefix}-item-control {

      label {
        justify-content: flex-start;
        min-width: 100%;
        display: flex;
      }
    }
  }

  >.#{$antPrefix}-row:not(:last-child) {
    border: 1px solid $borderColor;
    padding: 8px 8px 0 8px;
    background: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    border-radius: 4px;
  }

  .anticon-minus-circle {
    color: $disabledColor;
    font-size: 20px;

    &:hover {
      color: $errorColor;
    }
  }
}