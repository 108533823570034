@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix}, .#{$antPrefix}-form-item  {
  color: $textColor;

  .#{$prefix}.#{$prefix}-drag {
    background: $componentBackground;
  }
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $primaryColor !important
}

.ant-upload.ant-upload-drag:hover {
  border-color: $primaryColor !important
}
 