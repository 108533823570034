@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $menuMainColor;
  &-header,
  &-sider,
  &-sider-children,
  &-sider-trigger {
    background: $menuMainColor;
  }
}

.information-card {
  background: $backgroundCard;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 4px 4px 8px rgba(38, 100, 123, 0.1);
  h1 {
    color: $textColor;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1.3em;
  }
}

