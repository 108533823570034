$antPrefix: ant;

$sorterUnselected: var(--sorterUnselected);
$backgroundCard: var(--backgroundCard);
$blackText: var(--blackText);
$textColor: var(--textColor);
$whiteColor: var(--whiteColor);
$menuListColor: var(--menuListColor);
$menuMainColor: var(--menuMainColor);
$clientColor: var(--clientColor);
$bodyBackground: var(--bodyBackground);
$primaryColor: var(--primaryColor);
$addColor: var(--addColor);
$primaryHover: var(--primaryHover);
$primaryColorA04: var(--primaryColorA04);
$primaryColorA07: var(--primaryColorA07);
$primaryContrast: var(--primaryContrast);
$secondaryColor: var(--secondaryColor);
$secondaryHover: var(--secondaryHover);
$tabsGray: var(--tabsGray);
$componentBackground: var(--componentBackground);
$menuDropdownBackground: var(--menuDropdownBackground);
$subMenuBackground: var(--subMenuBackground);
$contrastBackground: var(--contrastBackground);
$textColor: var(--textColor);
$textColorSecondary: var(--textColorSecondary);
$textColorInvert: var(--textColorInvert);
$textColorHover: var(--textColorHover);
$textColorA015: var(--textColorA015);
$textColorA075: var(--textColorA075);
$borderColor: var(--borderColor);
$borderColorA06: var(--borderColorA06);
$disabledColor: var(--disabledColor);
$disabledColorA04: var(--disabledColorA04);
$disabledColorA06: var(--disabledColorA06);
$itemActiveBackground: var(--itemActiveBackground);
$itemHoverBackground: var(--itemHoverBackground);
$itemActiveColor: var(--itemActiveColor);
$itemHoverColor: var(--itemHoverColor);
$logo: var(--logo);
$logoSmall: var(--logoSmall);
$errorColor: var(--errorColor);
$error100: var(--error100);
$errorA08: var(--errorA08);
$pickerDropdown: var(--pickerDropdown);
$opacitySVG: var(--opacitySVG);
$opacitySpin: var(--opacitySpin);

