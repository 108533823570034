@import 'variables';

$prefix: $antPrefix + -menu;
$darkClass: $prefix + -dark;

$activeArrowClass: '.#{$prefix}-item:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow';
$activeLinkClass: '.#{$prefix}-item:hover,.#{$prefix}-item-active,.#{$prefix}-submenu-active,.#{$prefix}-submenu-open,.#{$prefix}-submenu-selected,.#{$prefix}-submenu-title:hover';
$menuBorderRight: '.#{$prefix}-vertical .#{$prefix}-item::after, .#{$prefix}-vertical-left .#{$prefix}-item::after, .#{$prefix}-vertical-right .#{$prefix}-item::after, .#{$prefix}-inline .#{$prefix}-item::after';

@mixin selectedItemStyle($prefixClass) {
  &.#{$prefixClass}:not(.#{$prefix}-horizontal) .#{$prefix}-item-selected {
    color: $primaryColor;
    background-color: $itemHoverBackground;
  }
  &.#{$prefixClass} .#{$prefix}-item-selected {
    & > a,
    & > a:hover {
      color: $textColor;
      font-weight: 600;
    }
  }
}
%baseMenu {
  #{$activeLinkClass} {
    & > a {
      color: $primaryColor;
    }
  }
  color: $textColor;
  background: $componentBackground;
}

%lightMenu {
  @extend %baseMenu;
  .#{$prefix}-item:hover,
  .#{$prefix}-item,
  .#{$prefix}-item-active,
  .#{$prefix}:not(.#{$prefix}-inline) .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-title:hover {
    color: $textColor;
  }
  .#{$prefix}-item:active,
  .#{$prefix}-submenu-title:active {
    background-color: transparent;
  }
}

.#{$prefix}-sub.#{$prefix}-inline > .#{$prefix}-item {
  &.#{$prefix}-item-active {
    background: rgba($color: white, $alpha: 0);
    a {
      color: $textColor;
    }
  }
}

.#{$prefix}-inline,
.#{$prefix}-vertical {
  border-right: transparent;
}

.#{$prefix} .#{$prefix}-item > a {
  color: $textColor;
}

.#{$prefix} .#{$prefix}-item:hover,
.#{$prefix} .#{$prefix}-item-active {
  background-color: rgba($color: white, $alpha: 0.7);
  a {
    color: $textColor;
  }
}

.#{$prefix} {
  @extend %lightMenu;
  @include selectedItemStyle($prefix);
}

.#{$prefix}-submenu > .ant-menu {
  color: $textColor;
  background: $menuListColor;

  .#{$prefix}-item:hover {
    background-color: rgba($color: white, $alpha: 0.7);
  }
}

#{$menuBorderRight} {
  border-color: $primaryColor;
}

.#{$prefix}-item-selected {
  color: $primaryColor;
}

.#{$prefix}-submenu-inline
  > .#{$prefix}-submenu-title
  .#{$prefix}-submenu-arrow {
  &::before,
  &::after {
    background-image: linear-gradient(to right, $textColor, $textColor);
  }
}
.#{$prefix}-submenu-selected
  > .#{$prefix}-submenu-title
  .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu-open > .#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu
  > .#{$prefix}-submenu-title:hover
  .#{$prefix}-submenu-arrow {
  &::before,
  &::after {
    background-image: linear-gradient(to right, $textColor, $textColor);
  }
}
.#{$prefix}.#{$prefix}-inline .#{$prefix}-item-selected {
  color: $textColor;
  font-weight: 600;
  &::after {
    display: none;
  }
}

.#{$darkClass} {
  @extend %baseMenu;
  &.#{$prefix} {
    background: $componentBackground;
  }
  .#{$prefix}-inline.#{$prefix}-sub,
  &.#{$prefix}-sub {
    background: transparent;
  }
  .#{$prefix}-item:hover,
  .#{$prefix}-item-active,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-selected,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  @include selectedItemStyle($darkClass);
}

.#{$prefix}-submenu-selected {
  color: $textColor;
}

