@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }
      &-item-label {
        background: $contrastBackground;
      }
    }
  }
  &-title,
  &-item-label,
  &-item-content {
    color: $textColor;
  }
}

.information-card {
  .#{$prefix}-item-label {
    color: black;
  }

  .#{$prefix} .#{$prefix}-row > th {
    padding-bottom: 0;
  }

  .#{$prefix} .#{$prefix}-row > td {
    padding-bottom: 2em;
  }
}

