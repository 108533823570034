@import 'variables';

$prefix: $antPrefix + -image;

.#{$prefix}.bank-image {
  border-radius: 60px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 10px;

  .#{$prefix}-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
